<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st7" d="M1.111,21.909c2.033,0.053,4.069,0.202,6.098,0.131c2.182-0.077,3.428,0.749,4.236,2.914   c3.593,9.62,6.231,19.548,9.354,29.318c1.573,4.921,3.024,9.883,4.57,14.813c0.211,0.674,0.207,1.485,1.047,2.141   c4.924-14.077,11.795-27.325,14.633-42.043c0.369-1.911,0.53-3.823,0.087-5.724c-0.22-0.94-0.11-1.376,0.953-1.364   c3.037,0.034,6.075,0.012,9.444,0.012c-3.496,5.696-6.158,11.497-8.7,17.357c-5.197,11.979-9.707,24.238-14.336,36.447   c-0.766,2.021-1.617,2.892-3.868,2.771c-4.133-0.221-4.108-0.072-5.205-4.122C15.349,59.516,11.083,44.531,5.1,30.122   c-1.118-2.692-2.151-5.449-3.989-7.768C1.111,22.206,1.111,22.057,1.111,21.909z"/>
            <path class="st7" d="M76.235,78.258c1.674-4.027,1.691-8.036,1.759-12.004c0.205-11.983,0.355-23.968-0.16-35.949   c-0.117-2.729-0.363-5.439-1.462-7.933c0.41-0.498,0.878-0.303,1.277-0.295c6.497,0.134,12.968-0.335,19.436-0.857   c3.795-0.306,7.572-0.286,11.309,0.579c8.909,2.061,14.69,7.545,17.118,16.467c1.994,7.325,1.742,14.708-0.776,21.839   c-3.916,11.089-12.089,16.921-23.414,18.195C93.048,79.23,84.754,77.828,76.235,78.258z M85.651,49.515   c0,6.435-0.065,12.87,0.022,19.304c0.06,4.469,1.213,5.608,5.536,5.976c2.618,0.223,5.234,0.287,7.859,0.036   c9.11-0.872,15.628-6.39,17.879-15.375c1.535-6.127,1.542-12.334-0.023-18.445c-2.051-8.005-6.928-13.373-14.968-15.449   c-4.592-1.186-9.239-0.988-13.898-0.365c-1.475,0.197-2.057,0.786-2.172,2.36C85.352,34.873,85.555,42.196,85.651,49.515z"/>
            <path class="st7" d="M127.912,22.304c0.343-0.135,0.47-0.23,0.596-0.228c9.907,0.187,8.02-1.647,11.235,7.88   c4.315,12.788,8.054,25.776,12.046,38.676c0.253,0.817,0.549,1.619,0.977,2.876c2.808-7.151,5.427-13.871,8.086-20.574   c2.532-6.382,4.857-12.829,6.336-19.56c0.589-2.679,1.156-5.377,0.477-8.115c-0.247-0.997,0.125-1.158,0.933-1.155   c3.043,0.011,6.087,0.004,9.44,0.004c-0.86,1.528-1.625,2.821-2.327,4.148c-6.424,12.141-11.213,25.003-16.147,37.8   c-1.671,4.335-3.367,8.66-4.977,13.017c-0.421,1.139-0.996,1.563-2.226,1.579c-5.266,0.067-5.261,0.111-6.653-5.076   c-3.788-14.123-7.893-28.143-13.307-41.732C131.118,28.626,129.681,25.481,127.912,22.304z"/>
            <path class="st7" d="M237.167,69.307c-0.457,2.798-0.854,5.027-1.174,7.267c-0.172,1.204-0.999,1.251-1.901,1.364   c-6.622,0.825-13.261,0.212-19.892,0.269c-3.704,0.032-7.408,0.006-11.272,0.006c2.127-6.448,1.67-12.91,1.726-19.306   c0.089-10.138,0.271-20.282-0.377-30.411c-0.132-2.068-0.44-4.108-1.311-5.944c0.192-0.546,0.593-0.476,0.937-0.478   c8.898-0.069,17.795-0.103,26.692-0.225c1.498-0.021,2.273,0.234,2.213,1.995c-0.055,1.608,0.238,3.229,0.422,5.361   c-2.793-3.308-6.256-3.614-9.745-3.761c-2.914-0.123-5.834-0.096-8.747-0.229c-1.42-0.065-2.076,0.331-2.224,1.912   c-0.567,6.059-0.361,12.129-0.476,18.195c-0.029,1.513,0.869,1.426,1.847,1.43c6.339,0.026,12.685,0.249,19.314-0.863   c-1.987,2.455-4.417,3.479-7.056,4.047c-4.091,0.882-8.253,0.961-12.413,0.858c-1.141-0.028-1.725,0.102-1.697,1.491   c0.136,6.879-0.149,13.765,0.466,20.639c0.141,1.579,0.638,2.217,2.259,2.125c3.348-0.191,6.713-0.107,10.054-0.359   C229.308,74.349,233.585,73.284,237.167,69.307z"/>
            <path class="st7" d="M191.945,78.104c-3.502,0-7.051,0-10.621,0c2.376-8.781,2.381-47.476,0.003-55.946c3.568,0,7.111,0,10.616,0   c-2.289,9.212-1.542,18.626-1.552,27.969C190.381,59.408,189.682,68.76,191.945,78.104z"/>
            <path class="st7" d="M64.928,78.183c-0.212,0-0.865,0-1.518,0c-2.84,0-5.681,0-8.081,0c1.722-18.711,1.632-37.343,0.034-56.045   c2.991,0,6.538,0,9.585,0C63.163,40.774,63.204,59.353,64.928,78.183z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'vidvie-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
